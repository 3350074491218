import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { MIXPANEL_HEADER_KEYS, MIXPANEL_HEADER_PARAMS, MIXPANEL_HEADER_VALUE } from '../../constants'
import selector from './selector'
import useStyles from './styles'
import { getProductTourSteps } from './utils/index'

import { Mixpanel } from '@/analytics'
import { ArrowDownIcon, ArrowUpIcon, CapIcon, QuestionFilledIcon, SocialBigLogoIcon } from '@/components/Icons'
import Popover from '@/components/Popover'
import ProductTour from '@/components/ProductTour'
import { SlateGreyLighten22, White } from '@/const/colors'
import { LANDING_HELP_URL } from '@/const/consts'
import { IsNotViewableEducationMaterialsFeature } from '@/const/localStorage'
import { Routes } from '@/const/routes'
import { setUserPlugin } from '@/store/user'
import { PLUGINS } from '@/store/user/types'
import { getFromStorage, setToStorage } from '@/utils/localStorage'

const Education = () => {
  const classes = useStyles()
  const [isOpenMenu, setIsMenuOpen] = useState(false)
  const [isProductTourOpened, setIsProductTourOpened] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()
  const { shouldStartProductTour, isDesktop } = useSelector(selector)

  const isNew = getFromStorage(IsNotViewableEducationMaterialsFeature) ?? true

  const buttonClickHandler = () => setIsMenuOpen(!isOpenMenu)

  const closeHandler = () => setIsMenuOpen(false)

  const educationClickHandler = () => {
    Mixpanel.track(MIXPANEL_HEADER_KEYS.EDUCATION_MATERIALS_CLICK, {
      [MIXPANEL_HEADER_PARAMS.PLACE]: MIXPANEL_HEADER_VALUE.HEADER,
    })

    setToStorage(IsNotViewableEducationMaterialsFeature, false)
    setIsMenuOpen(false)
  }

  const customerAssistanceClickHandler = () => {
    Mixpanel.track(MIXPANEL_HEADER_KEYS.GO_TO_HELP, {
      [MIXPANEL_HEADER_PARAMS.PLACE]: MIXPANEL_HEADER_VALUE.HEADER,
    })
    setIsMenuOpen(false)
  }

  const closeProductTourHandler = () => {
    setIsProductTourOpened(false)
    dispatch(setUserPlugin(PLUGINS.HEADER_HELP_PRODUCT_TOUR_IS_FINISHED))
  }

  const redirectTourHandler = () => {
    history.push(Routes.EDUCATION)
    setToStorage(IsNotViewableEducationMaterialsFeature, false)
    setIsProductTourOpened(false)
    dispatch(setUserPlugin(PLUGINS.HEADER_HELP_PRODUCT_TOUR_IS_FINISHED))
  }

  useEffect(() => {
    if (shouldStartProductTour && isOpenMenu) {
      setIsProductTourOpened(true)
    }
  }, [shouldStartProductTour, isOpenMenu])

  return (
    <div className={classes.root}>
      <div onClick={buttonClickHandler} className={classes.button}>
        {isDesktop && (
          <div className={classes.icon}>
            <CapIcon style={{ width: 20, height: 20 }} />
          </div>
        )}
        <div className={classes.textWrapper}>
          <FormattedMessage id="header.help" />
        </div>
        <div className={classes.arrowWrapper}>
          {isOpenMenu ? <ArrowUpIcon fill={White} /> : <ArrowDownIcon fill={White} />}
        </div>
        {isNew && (
          <div className={classes.newLabel}>
            <FormattedMessage id="header.help.newLabel" />
          </div>
        )}
      </div>
      {isOpenMenu && (
        <OutsideClickHandler onOutsideClick={closeHandler}>
          <Popover className={classes.popover}>
            <div className={classes.container}>
              <NavLink exact={false} to={Routes.EDUCATION} onClick={educationClickHandler} className={classes.link}>
                <div data-tour-id="header-education-first-link" className={classes.popoverButton}>
                  <div className={classes.iconWrapper}>
                    <SocialBigLogoIcon style={{ width: 28, height: 28 }} />
                  </div>
                  <div className={classes.popoverTextWrapper}>
                    <div className={classes.title}>
                      <FormattedMessage id="header.help.firstTitle" />
                    </div>
                    <div className={classes.description}>
                      <FormattedMessage id="header.help.firstDescription" />
                    </div>
                  </div>
                </div>
              </NavLink>
              <a
                href={LANDING_HELP_URL}
                rel="noopener noreferrer"
                target="_blank"
                className={classes.link}
                onClick={customerAssistanceClickHandler}
              >
                <div className={classes.popoverButton}>
                  <div className={classes.iconWrapper}>
                    <QuestionFilledIcon fill={SlateGreyLighten22} style={{ width: 28, height: 28 }} />
                  </div>
                  <div className={classes.popoverTextWrapper}>
                    <div className={classes.title}>
                      <FormattedMessage id="header.help.secondTitle" />
                    </div>
                    <div className={classes.description}>
                      <FormattedMessage id="header.help.secondDescription" />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </Popover>
        </OutsideClickHandler>
      )}
      <ProductTour
        steps={getProductTourSteps(redirectTourHandler)}
        isOpen={isProductTourOpened}
        onRequestClose={closeProductTourHandler}
        showButtons={false}
        showNavigation={false}
      />
    </div>
  )
}

export default Education
