import React, { ReactNode } from 'react'

import useStyles from './styles'

export interface IPreviewWrapper {
  children: ReactNode | null
}

const PreviewWrapper: React.FC<IPreviewWrapper> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.previewWrapper}>{children}</div>
}

export default PreviewWrapper
