import React, { FC } from 'react'

import LoaderIcon from '../Icons/LoaderIcon'
import { LoaderBlock, LoaderIconWrapper } from './styled'

/**
 * Component for spinner
 */
const Loader: FC<any> = ({ pastDelay = true, ...props }: any) => {
  return (
    <LoaderBlock {...props} data-testid="loader">
      <LoaderIconWrapper>
        <LoaderIcon />
      </LoaderIconWrapper>
    </LoaderBlock>
  )
}

export default Loader
