import React, { FC } from 'react'

import LogoIcon from '../Icons/LogoIcon'
import { StyledLogo } from './styled'

export interface ILogoProps {
  /** Color for fill SVG: HEX, RGBA, RGB, HSL или имя */
  color?: string
  fill?: string
  fillBg?: string
}

/**
 * Логотип SVG в теге `<figure>`. Размер зависит от родительского блока.
 */
const Logo: FC<ILogoProps> = ({ color = '#000', fill, fillBg, ...props }: ILogoProps) => (
  <StyledLogo color={color} {...props}>
    <LogoIcon fill={fill} fillBg={fillBg} />
  </StyledLogo>
)

export default Logo
