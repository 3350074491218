import React from 'react'

import useStyles from './styles'

interface Props {
  children: React.ReactNode
}

export const ErrorMessage: React.FC<Props> = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.errorMessage}>{children}</div>
}
