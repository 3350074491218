import React from 'react'
import Tour, { ReactourProps } from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'

import useStyles from './styles'

const disableBody = (target: HTMLElement | Element) => disableBodyScroll(target)
const enableBody = (target: HTMLElement | Element) => enableBodyScroll(target)

const ProductTour = (props: ReactourProps) => {
  const {
    disableInteraction = true,
    maskSpace = 0,
    closeWithMask = false,
    children,
    showButtons = true,
    showNavigation = true,
    showNumber = false,
    showCloseButton = true,
    onRequestClose,
    rounded = 8,
    steps,
    isOpen,
    startAt,
    className,
  } = props
  const classes = useStyles()

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      className={cn(className, classes.wrapper)}
      disableInteraction={disableInteraction}
      maskSpace={maskSpace}
      closeWithMask={closeWithMask}
      showButtons={showButtons}
      showNavigation={showNavigation}
      showNumber={showNumber}
      showCloseButton={showCloseButton}
      onRequestClose={onRequestClose}
      rounded={rounded}
      startAt={startAt}
    >
      {children}
    </Tour>
  )
}

export default ProductTour
