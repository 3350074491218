import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react'
import InputMask from 'react-input-mask'
import { DefaultTheme } from 'react-jss'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import { EyeHideIcon, EyeIcon } from '../../components/Icons'
import { SlateGreyLighten22 } from '../../const/colors'
import { INPUT_TYPE } from '../../const/form'
import useGlobalStyles from '../../const/globalStyles'
import { ErrorMessage } from '../ErrorMessage'
import useStyles from './styles'
import { IInputSMBProps, INPUT_SIZE, INPUT_VARIANT, IResponseProps } from './types'

const Input = forwardRef((props: IInputSMBProps & { theme?: DefaultTheme }, ref: any) => {
  const {
    variant = INPUT_VARIANT.default,
    size = INPUT_SIZE.small,
    defaultValue,
    value,
    name,
    placeholder = '',
    mask,
    onChange,
    onBlur,
    onFocus,
    onClick,
    onKeyDown,
    inputRef,
    disabled = false,
    type = 'text',
    error = false,
    fullWidth = false,
    rows,
    min,
    max,
    className,
    request,
    suggestionList,
    alwaysShowMask = true,
    leftIcon,
    dataTestId = name,
    id = name,
    errorMsg,
    maxLength,
    label,
    errorMessage,
    helperText,
  } = props

  const classes = useStyles(props)
  const globalClasses = useGlobalStyles()

  const [suggestionResponse, setSuggestionResponse] = useState<IResponseProps | null>(null)

  const [isShowPassword, setIsShowPassword] = useState(false)

  useImperativeHandle(ref, () => ({
    clearSuggestions: () => {
      setSuggestionResponse(null)
    },
    focus: () => {
      if (ref && ref.current) {
        ref.current.focus()
      }
    },
  }))

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e)
    }

    if (request) {
      request(e.target.value).then((response) => {
        setSuggestionResponse(response as IResponseProps)
      })
    }
  }

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(e)
    }
  }

  const onClearSuggestions = () => {
    setSuggestionResponse(null)
  }

  const onShowPasswordHandler = () => {
    setIsShowPassword(!isShowPassword)
  }

  return (
    <div
      className={cn(
        classes.root,
        classes[variant],
        classes[size],
        {
          [classes.disabled]: disabled,
          [classes.fullWidth]: fullWidth,
          [classes.error]: error,
          [classes.leftIcon]: leftIcon,
        },
        className
      )}
    >
      {leftIcon && <div className={classes.leftIconWrapper}>{leftIcon}</div>}
      {mask ? (
        <InputMask
          placeholder={placeholder}
          mask={mask}
          value={defaultValue || value}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          onFocus={onFocus}
          onClick={onClick}
          name={name}
          inputRef={inputRef}
          className={classes.input}
          alwaysShowMask={alwaysShowMask}
        />
      ) : rows ? (
        <>
          {label && <div className={classes.label}>{label}</div>}
          <textarea
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            onFocus={onFocus}
            onClick={onClick}
            rows={rows}
            disabled={disabled}
            className={classes.input}
            id={id}
          />
          {!errorMessage && helperText && <div className={classes.helperText}>{helperText}</div>}
          {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
        </>
      ) : (
        <>
          {label && <div className={classes.label}>{label}</div>}
          <input
            type={variant === INPUT_VARIANT.password ? (isShowPassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD) : type}
            name={name}
            placeholder={placeholder}
            value={defaultValue || value}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            onFocus={onFocus}
            onClick={onClick}
            onKeyDown={onKeyDown}
            ref={inputRef}
            disabled={disabled}
            className={classes.input}
            min={min}
            max={max}
            data-testid={dataTestId}
            maxLength={maxLength}
            id={id}
          />
          {!errorMessage && helperText && <div className={classes.helperText}>{helperText}</div>}
          {errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
          {suggestionResponse && suggestionList && (
            <OutsideClickHandler onOutsideClick={onClearSuggestions}>
              <div className={cn(classes.suggestion, globalClasses.scrollbar)}>
                {suggestionList(suggestionResponse)}
              </div>
            </OutsideClickHandler>
          )}
          {variant === INPUT_VARIANT.password && Boolean(value?.toString()?.length) && (
            <div className={classes.showPasswordWrapper} onClick={onShowPasswordHandler}>
              {isShowPassword ? (
                <EyeIcon style={{ width: 20, height: 20 }} fill={SlateGreyLighten22} />
              ) : (
                <EyeHideIcon style={{ width: 20, height: 20 }} fill={SlateGreyLighten22} fillBg={SlateGreyLighten22} />
              )}
            </div>
          )}
          {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        </>
      )}
    </div>
  )
})

export { Input }
