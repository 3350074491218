import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { AxiosResponse } from 'axios'
import hmacSHA256 from 'crypto-js/hmac-sha256'

import { AllStartupProps, IStartupPropsFromState } from './types'

import { Mixpanel } from '@/analytics'
import Loader from '@/components/Loader'
import { openAppModal, setAppLoaded } from '@/store/app'
import { IModal, MODALS } from '@/store/app/types'
import { getAvailablePolls, getUserVotes } from '@/store/polls'
import { getReferralCode } from '@/store/referral'
import { MyThunkDispatch } from '@/store/store'
import {
  getProfile,
  getUserAvailablePlugins,
  getUserInfo,
  getUserPlugins,
  logoutUser,
  refreshToken,
  setAuthorized,
} from '@/store/user'
import { IUserInfoResponse } from '@/store/user/types'
import { CarrotQuestEvents, CloseDocTypes } from '@/types'
import IApplicationState from '@/types/state'
import { baseCatchError, jwt } from '@/utils'

export class Startup extends PureComponent<AllStartupProps> {
  constructor(props: AllStartupProps) {
    super(props)

    this.setLoadedWithoutAuth = this.setLoadedWithoutAuth.bind(this)
  }

  public componentDidMount() {
    const tokens = jwt.get()

    window.localStorage.setItem('activeFilter', '')

    Mixpanel.track('VisitTool')

    if (tokens.accessToken || tokens.refToken) {
      this.props
        .getUserInfo()
        .then((userResponse: AxiosResponse<IUserInfoResponse>) => {
          if (userResponse.status === 200) {
            this.props
              .getProfile()
              .then((profileResponse: any) => {
                if (profileResponse.status === 200) {
                  Sentry.configureScope((scope) => {
                    scope.setUser({ email: userResponse?.data?.email })
                  })

                  Mixpanel.identify(userResponse?.data?.email)

                  const winw = window as any

                  if (
                    winw.carrotquest &&
                    winw.carrotquest.auth &&
                    userResponse?.data?.id &&
                    process.env.REACT_APP_CC_AUTH_KEY
                  ) {
                    const userId = userResponse?.data?.id.toString()
                    const hmac = hmacSHA256(userId, process.env.REACT_APP_CC_AUTH_KEY).toString()
                    winw.carrotquest.auth(userId, hmac)
                    winw.carrotquest.track(CarrotQuestEvents.AUTH, {
                      $email: userResponse?.data?.email.toLowerCase(),
                      $name: profileResponse?.data?.name,
                    })
                  }

                  Mixpanel.set({
                    $email: userResponse?.data?.email.toLowerCase(),
                    $name: profileResponse?.data?.name,
                    'Archived AdAccounts': profileResponse?.data?.archived_accounts,
                    'Client ID': profileResponse?.data?.id,
                    'Facebook AdAccounts': profileResponse?.data?.facebook,
                    Industry: profileResponse?.data?.industry,
                    'Paying Customer': profileResponse?.data?.paying_customer ? 'Yes' : 'No',
                    'TikTok AdAccounts': profileResponse?.data?.account_counts?.tiktok,
                    'VK AdAccounts': profileResponse?.data?.account_counts?.vk,
                    'myTarget AdAccounts': profileResponse?.data?.account_counts?.mytarget,
                    'Yandex AdAccounts': profileResponse?.data?.account_counts?.yandex,
                    'Avito AdAccounts': profileResponse?.data?.account_counts?.avito,
                  })

                  Mixpanel.identify(userResponse?.data?.email)

                  if (
                    userResponse?.data?.has_profile &&
                    !profileResponse?.data?.new_aitarget_offer &&
                    profileResponse?.data?.closedoc_type === CloseDocTypes.PAYMENT
                  ) {
                    this.props.openAppModal({ name: MODALS.NEW_OFFER })
                  }

                  if (userResponse?.data?.has_profile) {
                    this.props
                      .getUserPlugins()
                      .then(() => {
                        this.props.setLoaded(true)

                        Mixpanel.set({
                          Audiences: profileResponse?.data?.smart_targetings ? 'Yes' : 'No',
                          'Feed Manager': profileResponse?.data?.feedgen ? 'Yes' : 'No',
                          Optimizer: profileResponse?.data?.optimizer ? 'Yes' : 'No',
                        })
                        Mixpanel.identify(userResponse?.data?.email)
                      })
                      .catch(baseCatchError)

                    this.props.getUserAvailablePlugins().catch(baseCatchError)
                    this.props.getUserVotes().catch(baseCatchError)
                    this.props.getAvailablePolls().catch(baseCatchError)
                    this.props.getReferralCode().catch(baseCatchError)
                  } else {
                    this.props.setLoaded(true)
                  }
                }
              })
              .catch(baseCatchError)
          } else {
            this.props.logout()
          }
        })
        .catch(this.setLoadedWithoutAuth)
    } else {
      this.setLoadedWithoutAuth()
    }
  }

  public render() {
    return this.props.loaded ? this.props.children : <Loader />
  }

  public setLoadedWithoutAuth() {
    this.props.setAuthorized(false)
    this.props.setLoaded(true)
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  authorized: state.user.isAuthorized,
  email: state.user.email,
  loaded: state.app.loaded,
  user: state.user,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  getProfile: () => dispatch(getProfile()),
  getReferralCode: () => dispatch(getReferralCode()),
  getUserAvailablePlugins: () => dispatch(getUserAvailablePlugins()),
  getAvailablePolls: () => dispatch(getAvailablePolls()),
  getUserVotes: () => dispatch(getUserVotes()),
  getUserInfo: () => dispatch(getUserInfo()),
  getUserPlugins: () => dispatch(getUserPlugins()),
  logout: () => dispatch(logoutUser()),
  refreshToken: (token: string | null) => dispatch(refreshToken(token)),
  setAuthorized: (authorized: boolean) => dispatch(setAuthorized(authorized)),
  setLoaded: (loaded: boolean) => dispatch(setAppLoaded(loaded)),
  openAppModal: (args: IModal) => dispatch(openAppModal(args)),
})

const StartupClass = connect<IStartupPropsFromState, any, any, any>(mapStateToProps, mapDispatchToProps)(Startup)

export default StartupClass
