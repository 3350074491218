import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  BackgroundGrey,
  blackClrShadow,
  blueClrLight,
  blueClrLightest,
  brandClr,
  brandClrDark,
  brandClrDarkPurple,
  brandClrLightest,
  GreyBase,
  greyClr,
  greyClrSilver,
  lemonClr,
  PinkBase,
  pinkClrDark,
  PurpleBase,
  purpleClr,
  purpleClrLight,
  White,
} from '../../const/colors'
import { baseFontFamily, baseTransDur, smallestFont, smallFont, WidthBPs } from '../../const/commonStyles'
import { IButtonBlockProps, ITheme } from './types'

const generalStyles = `
  display: block;
  font: 500 ${smallFont} ${baseFontFamily};
  letter-spacing: 0.2px;

  border-radius: 4px;
  border: 2px solid transparent;
  background: transparent;

  cursor: pointer;
  user-select: none;
  touch-action: manipulation;

  text-decoration: none;

  transition-duration: ${baseTransDur};

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transition-duration: ${baseTransDur};
    outline: none;
  }

  @media (min-width: ${WidthBPs.MEDIUM}) {
    white-space: nowrap;
  }
`

const size: any = {
  default: `padding: 16px 20px 16px;`,
  extraSmall: `padding: 10px 15px;`,
  small: `padding: 10px 10px 9px;`,
}

const theme: ITheme = {
  primary: `background-color: ${brandClr};
    border-color: ${brandClr};
    color: ${White};
    fill: ${White};
    stroke: ${White};

    &:hover:not(:disabled) {
      background-color: ${brandClrDark};
      border-color: ${brandClrDark};
    }
    &:focus:not(:disabled) {
      background-color: ${brandClrDark};
      border-color: ${brandClrDark};
    }`,

  brand: `background-color: ${brandClrLightest};
    border-color: ${brandClrLightest};
    color: ${purpleClrLight};
    fill: ${purpleClrLight};
    stroke: ${purpleClrLight};

    &:hover:not(:disabled) {
      background-color: ${purpleClr};
      border-color: ${purpleClr};
      color: ${White};
      fill: ${White};
      stroke: ${White};
    }
    &:focus:not(:disabled) {
      background-color: ${purpleClr};
      border-color: ${purpleClr};
      color: ${White};
      fill: ${White};
      stroke: ${White};
    }`,

  pink: `background-color: ${PinkBase};
    border-color: ${PinkBase};
    color: ${White};
    fill: ${White};
    stroke: ${White};
    box-shadow: 0px 4px 10px ${blackClrShadow};

    &:hover:not(:disabled) {
      background-color: ${pinkClrDark};
      border-color: ${pinkClrDark};
      color: ${White};
      fill: ${White};
      stroke: ${White};
      box-shadow: 0 0 0 transparent;
    }

    &:focus:not(:disabled) {
      background-color: ${pinkClrDark};
      border-color: ${pinkClrDark};
      color: ${White};
      fill: ${White};
      stroke: ${White};
      box-shadow: 0 0 0 transparent;
    }`,

  gray: `background-color: ${blueClrLightest};
    border-color: ${blueClrLightest};
    color: ${White};
    fill: ${White};
    stroke: ${White};
    &:hover:not(:disabled) {
      background-color: ${blueClrLight};
      border-color: ${blueClrLight};
    }
    &:focus:not(:disabled) {
      background-color: ${blueClrLight};
      border-color: ${blueClrLight};
    }`,

  light_gray: `background-color: ${BackgroundGrey};
    border-color: ${BackgroundGrey};
    color: ${greyClr};
    fill: ${greyClr};
    stroke: ${greyClr};
    &:hover:not(:disabled) {
      background-color: ${blueClrLight};
      border-color: ${blueClrLight};
      color: #fff;
    }
    &:focus:not(:disabled) {
      background-color: ${blueClrLight};
      border-color: ${blueClrLight};
      color: #fff;
    }`,

  linked_text: `background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    color: ${brandClr};
    fill: ${brandClr};
    stroke: ${brandClr};
    &:hover:not(:disabled) {
      background-color: transparent;
      border-color: transparent;
      text-decoration: none;
    }
    &:focus:not(:disabled) {
      background-color: transparent;
      border-color: transparent;
      text-decoration: none;
    }`,

  text: `background-color: transparent;
    border-color: transparent;
    color: ${greyClr};
    fill: ${greyClr};
    stroke: ${greyClr};
    &:hover:not(:disabled) {
      background-color: transparent;
      border-color: transparent;
    }
    &:focus:not(:disabled) {
      background-color: transparent;
      border-color: transparent;
    }`,

  bordered_text: `background-color: transparent;
    border-color: ${BackgroundGrey};
    color: ${greyClr};
    fill: ${greyClr};
    stroke: ${greyClr};
    &:hover:not(:disabled) {
      background-color: transparent;
      border-color: ${BackgroundGrey};
    }
    &:focus:not(:disabled) {
      background-color: transparent;
      border-color: ${BackgroundGrey};
    }`,

  yellow: `background-color: #FDF56C;
    border-color: #FDF56C;
    color: ${greyClr};
    fill: ${greyClr};
    stroke: ${greyClr};
    &:hover:not(:disabled) {
      background-color: ${greyClr};
      border-color: ${greyClr};
      color: ${lemonClr};
      fill: ${lemonClr};
      stroke: ${lemonClr};
    }
    &:focus:not(:disabled) {
      background-color: ${greyClr};
      border-color: ${greyClr};
      color: ${lemonClr};
      fill: ${lemonClr};
      stroke: ${lemonClr};
    }`,

  default: `
    color: ${greyClr};
    fill: ${greyClr};
    stroke: ${greyClr};
    background-color: transparent;
    border-color: ${greyClr};

    &:hover:not(:disabled) {
      background-color: transparent;
      border-color: ${brandClr};
      color: ${brandClr};
      fill: ${brandClr};
      stroke: ${brandClr};
    }
    &:focus:not(:disabled) {
      background-color: transparent;
      border-color: ${brandClr};
      color: ${brandClr};
      fill: ${brandClr};
      stroke: ${brandClr};
    }
  `,

  purple: `background-color: ${PurpleBase};
    border-color: ${PurpleBase};
    color: ${White};
    fill: ${White};
    stroke: ${White};

    font: 500 ${smallestFont} ${baseFontFamily};

    &:hover:not(:disabled) {
      background-color: ${brandClrDarkPurple};
      border-color: ${brandClrDarkPurple};
    }
    &:focus:not(:disabled) {
      background-color: ${brandClrDarkPurple};
      border-color: ${brandClrDarkPurple};
    }`,

  bordered_purple: `background-color: transparent;
    border: 1px solid transparent;
    border-color: ${greyClrSilver};
    color: ${GreyBase};
    fill: ${GreyBase};
    stroke: ${GreyBase};

    font: 500 ${smallestFont} ${baseFontFamily};

    &:hover:not(:disabled) {
      background-color: transparent;
      border-color: ${brandClr};
    }
    &:focus:not(:disabled) {
      background-color: transparent;
      border-color: ${brandClr};
    }`,
}

export const ButtonBlock = styled.button<IButtonBlockProps>`
  ${generalStyles}

  ${(props: IButtonBlockProps) => (props.size ? size[props.size] : size.default)}

  ${(props: IButtonBlockProps) => (props.theme ? theme[props.theme] : null)}
`

export const ButtonLink = styled(Link)<IButtonBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  ${generalStyles}

  ${(props: IButtonBlockProps) => (props.size ? size[props.size] : size.default)}

  ${(props: IButtonBlockProps) => (props.theme ? theme[props.theme] : null)}
`

export const ExternalLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  ${generalStyles}

  ${(props: IButtonBlockProps) => (props.size ? size[props.size] : size.default)}

  ${(props: IButtonBlockProps) => (props.theme ? theme[props.theme] : null)}
`
