import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Dispatch } from 'redux'

import { Mixpanel } from '../../analytics'
import { Routes } from '../../const/routes'
import { logoutUser, setAuthorized } from '../../store/user'
import IApplicationState from '../../types/state'

class Logout extends Component<any, any> {
  public componentDidMount(): void {
    Mixpanel.track('Logout')
    Mixpanel.reset()

    this.props.logout()
    this.props.setAuthorized(false)
  }

  public render() {
    return <Redirect to={Routes.SIGNIN} />
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  logout: () => dispatch(logoutUser()),
  setAuthorized: (authorized: boolean) => dispatch(setAuthorized(authorized)),
})

const LogoutClass = connect<any, Record<never, never>, any, IApplicationState>(null, mapDispatchToProps)(Logout)

export default LogoutClass
