import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import { openCampaignEditorReportModal } from './CampaignReport/modals/actions'
import { MODALS } from './CampaignReport/modals/types'
import {
  AdsetsResult,
  AdsResult,
  AsideLimitDraft,
  AsideStopDateDraft,
  AsideValues,
  CampaignsResult,
  DailyLevel,
  FilterStatus,
  FilterType,
  IAsideNavErrors,
  IDeleteBudgets,
  IDeleteDaysStop,
  ISetBudgets,
  ISetDaysStop,
  IUpdateBudgets,
  IUpdateDaysStop,
  MassEditingTypes,
} from './types'
import {
  checkLimitAdInherits,
  checkLimitAdsetsInherits,
  checkLimitCampaignsInherits,
  checkStopDateAdInherits,
  checkStopDateAdsetsInherits,
  checkStopDateCampaignsInherits,
  createNewAside,
  showAdFatherInheritsAlert,
  showAdsetsChildInheritsAlert,
  showAdsetsFatherInheritsAlert,
  showCampaignsChildInheritsAlert,
} from './utils/index'

import { API_URL, DELAY_FOR_MACROTASK_QUEUE } from '@/const'
import { MyThunkDispatch } from '@/store/store'
import { customAxios } from '@/utils'

export enum UpdateFilterStepsTypes {
  CAMPAIGN_REPORT_UPDATE_FILTER_STATUSES = 'CAMPAIGN_REPORT_UPDATE_FILTER_STATUSES',
  CAMPAIGN_REPORT_REMOVE_ALL_FILTER_STATUSES = 'CAMPAIGN_REPORT_REMOVE_ALL_FILTER_STATUSES',
  CAMPAIGN_REPORT_UPDATE_SEARCH_NAMES = 'CAMPAIGN_REPORT_UPDATE_SEARCH_NAMES',
  CAMPAIGN_REPORT_REMOVE_SEARCH_NAME = 'CAMPAIGN_REPORT_UPDATE_SEARCH_NAME',
  CAMPAIGN_REPORT_TABLE_CONFIG = 'CAMPAIGN_REPORT_TABLE_CONFIG',
  CAMPAIGN_REPORT_TABLE_CONFIG_LOADING = 'CAMPAIGN_REPORT_TABLE_CONFIG_LOADING',
}

export enum CampaignReportActionTypes {
  COMPAIGN_REPORT_SET_COMPANY = 'COMPAIGN_REPORT_SET_COMPANY',
  COMPAIGN_REPORT_SET_COMPANY_LOADING = 'COMPAIGN_REPORT_SET_COMPANY_LOADING',
  COMPAIGN_REPORT_SET_COMPANY_REQUEST_ERROR = 'COMPAIGN_REPORT_SET_COMPANY_REQUEST_ERROR',

  COMPAIGN_REPORT_SET_GROUP = 'COMPAIGN_REPORT_SET_GROUP',
  COMPAIGN_REPORT_SET_GROUP_LOADING = 'COMPAIGN_REPORT_SET_GROUP_LOADING',
  COMPAIGN_REPORT_SET_GROUP_REQUEST_ERROR = 'COMPAIGN_REPORT_SET_GROUP_REQUEST_ERROR',

  COMPAIGN_REPORT_SET_ADS = 'COMPAIGN_REPORT_SET_ADS',
  COMPAIGN_REPORT_SET_ADS_LOADING = 'COMPAIGN_REPORT_SET_ADS_LOADING',
  COMPAIGN_REPORT_SET_ADS_REQUEST_ERROR = 'COMPAIGN_REPORT_SET_ADS_REQUEST_ERROR',

  COMPAIGN_REPORT_SET_UNDISTRIBUTED = 'COMPAIGN_REPORT_SET_UNDISTRIBUTED',
  COMPAIGN_REPORT_SET_UNDISTRIBUTED_LOADING = 'COMPAIGN_REPORT_SET_UNDISTRIBUTED_LOADING',
  COMPAIGN_REPORT_SET_UNDISTRIBUTED_REQUEST_ERROR = 'COMPAIGN_REPORT_SET_UNDISTRIBUTED_REQUEST_ERROR',

  GET_LOADING_UPDATE_TABLE = 'GET_LOADING_UPDATE_TABLE',
  SET_GROUP_UPDATE_SELECTED = 'SET_GROUP_UPDATE_SELECTED',

  SET_SELECTED_UNDISTRIBUTED_IDS = 'SET_SELECTED_UNDISTRIBUTED_IDS',
  SET_SELECTED_AD_IDS = 'SET_SELECTED_AD_IDS',
  SET_SELECTED_ADSET_IDS = 'SET_SELECTED_ADSET_IDS',
  SET_SELECTED_CAMPAIGN_IDS = 'SET_SELECTED_CAMPAIGN_IDS',
  SET_SELECTED_ALL_ROWS_TYPE = 'SET_SELECTED_ALL_ROWS_TYPE',
  SET_UNDISTRIBUTED_ERROR = 'SET_UNDISTRIBUTED_ERROR',
  SET_AD_ERROR = 'SET_AD_ERROR',
  SET_ADSET_ERROR = 'SET_ADSET_ERROR',
  SET_CAMPAIGN_ERROR = 'SET_CAMPAIGN_ERROR',
  SET_UNDISTRIBUTED_LOADING = 'SET_UNDISTRIBUTED_LOADING',
  SET_AD_LOADING = 'SET_AD_LOADING',
  SET_ADSET_LOADING = 'SET_ADSET_LOADING',
  SET_CAMPAIGN_LOADING = 'SET_CAMPAIGN_LOADING',
  SET_FORCE_UPDATE_LOADING = 'SET_FORCE_UPDATE_LOADING',

  SET_ASIDE_ACTIVE_NAV_ITEM = 'SET_ASIDE_ACTIVE_NAV_ITEM',
  SET_ASIDE_NAV_ERROR = 'SET_ASIDE_NAV_ERROR',
  SET_ASIDE_NAV_DISABLED = 'SET_ASIDE_NAV_DISABLED',
  SET_ASIDE_RESET_NAV_DISABLED = 'SET_ASIDE_RESET_NAV_DISABLED',
  SET_ASIDE_LOADING = 'SET_ASIDE_LOADING',
  SET_ASIDE_OPENED = 'SET_ASIDE_OPENED',

  SET_ASIDE_LIMIT_DRAFT = 'SET_ASIDE_LIMIT_DRAFT',
  REMOVE_ASIDE_LIMIT_DRAFT = 'REMOVE_ASIDE_LIMIT_DRAFT',
  SET_ASIDE_STOP_DATE_DRAFT = 'SET_ASIDE_STOP_DATE_DRAFT',
  REMOVE_ASIDE_STOP_DATE_DRAFT = 'REMOVE_ASIDE_STOP_DATE_DRAFT',
  SET_ASIDE_VALUES = 'SET_ASIDE_VALUES',
  SET_ALL_ASIDE_VALUES = 'SET_ALL_ASIDE_VALUES',
  UPDATE_ASIDE_VALUES = 'UPDATE_ASIDE_VALUES',
  REMOVE_ASIDE_VALUES = 'REMOVE_ASIDE_VALUES',
  REMOVE_ALL_ASIDE_VALUES = 'REMOVE_ALL_ASIDE_VALUES',

  SET_ASIDE_STOP_DATE_RULE = 'SET_ASIDE_STOP_DATE_RULE',
  SET_ASIDE_LIMIT_RULE = 'SET_ASIDE_LIMIT_RULE',
}

export enum CampaignFormsActionTypes {
  SET_CAMPAIGN_SELECTED_LOADING = 'SET_CAMPAIGN_SELECTED_LOADING',
  SET_CAMPAIGN_SELECTED = 'SET_CAMPAIGN_SELECTED',
  SET_CAMPAIGN_SELECTED_UPDATE = 'SET_CAMPAIGN_SELECTED_UPDATE',
  SET_GROUP_SELECTED_LOADING = 'SET_GROUP_SELECTED_LOADING',
  SET_GROUP_SELECTED = 'SET_GROUP_SELECTED',
  SET_GROUP_UPDATE_SELECTED = 'SET_GROUP_UPDATE_SELECTED',
  SET_ACCOUNT_SELECTED_LOADING = 'SET_ACCOUNT_SELECTED_LOADING',
  SET_ACCOUNT_SELECTED = 'SET_ACCOUNT_SELECTED',
  SET_CHECK_ACTIVE_ACCOUNT_LOADING = 'SET_CHECK_ACTIVE_ACCOUNT_LOADING',
  SET_CHECK_ACTIVE_ACCOUNT = 'SET_CHECK_ACTIVE_ACCOUNT',
}

export function setCampaignReportUpdateFilterStatuses(type: FilterType, statusList?: FilterStatus[]): AnyAction {
  return { type: UpdateFilterStepsTypes.CAMPAIGN_REPORT_UPDATE_FILTER_STATUSES, data: { type, statusList } }
}

export function setCampaignReportRemoveAllFilterStatuses(): AnyAction {
  return { type: UpdateFilterStepsTypes.CAMPAIGN_REPORT_REMOVE_ALL_FILTER_STATUSES }
}

export function setCampaignReportUpdateSearchNames(type: FilterType, name: string): AnyAction {
  return { type: UpdateFilterStepsTypes.CAMPAIGN_REPORT_UPDATE_SEARCH_NAMES, data: { type, name } }
}

export function setCampaignReportRemoveSearchName(type: FilterType, name: string): AnyAction {
  return { type: UpdateFilterStepsTypes.CAMPAIGN_REPORT_REMOVE_SEARCH_NAME, data: { type, name } }
}

export function setCampaignReportTableConfig(data: any): AnyAction {
  return { type: UpdateFilterStepsTypes.CAMPAIGN_REPORT_TABLE_CONFIG, data }
}

// TABLE ACTION

export function setCampaignReportSetCompany(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_COMPANY, data }
}

export function updateCampaignReportCompanyRequestError(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_COMPANY_REQUEST_ERROR, data }
}

export function setCampaignReportSetGroup(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_GROUP, data }
}

export function updateCampaignReportGroupRequestError(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_GROUP_REQUEST_ERROR, data }
}

export function setCampaignReportSetAds(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_ADS, data }
}

export function updateCampaignReportAdsRequestError(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_ADS_REQUEST_ERROR, data }
}

export function setCampaignReportSetUndistributed(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_UNDISTRIBUTED, data }
}

export function updateCampaignReportUndistributedRequestError(data: any): AnyAction {
  return { type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_UNDISTRIBUTED_REQUEST_ERROR, data }
}

export function setLoadingUpdateTable(type: string, value: boolean): AnyAction {
  return { type: CampaignReportActionTypes.GET_LOADING_UPDATE_TABLE, data: { type, value } }
}

// ASYNC ACTION

const getQuery = (api: string, page?: number, query?: string) => {
  let url = api

  if (page && !query) {
    url = `${url}?page=${page}`
  }

  if (page && query) {
    url = `${url}?page=${page}&${query}`
  }

  if (!page && query) {
    url = `${url}?${query}`
  }

  return url
}

interface ISetAds {
  id: number | string
  page?: number
  query?: string
}

export const setAds =
  ({ id, page, query }: ISetAds) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    dispatch(updateCampaignReportAdsRequestError(false))
    let queryParams = `organized=true`

    if (query) {
      queryParams = `${query}&${queryParams}`
    }

    const url = getQuery(`${API_URL}/telegram_editor/${id}/ads/`, page, queryParams)

    dispatch({ type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_ADS_LOADING })

    try {
      const response = await customAxios(url, 'GET', null, true)

      dispatch(setCampaignReportSetAds(response.data))
      dispatch(setTableConfig(id))
      return response
    } catch (error) {
      dispatch(updateCampaignReportAdsRequestError(true))
      return Promise.reject(error)
    }
  }

interface ISetCompany {
  id: number | string
  page?: number
  query?: string
}

export const setCompany =
  ({ id, page = 1, query }: ISetCompany) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    dispatch(updateCampaignReportCompanyRequestError(false))
    const url = getQuery(`${API_URL}/telegram_editor/${id}/campaigns/`, page, query)
    dispatch({ type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_COMPANY_LOADING, data: true })

    try {
      const response = await customAxios(url, 'GET', null, true)

      dispatch(setCampaignReportSetCompany(response.data))
      dispatch(setTableConfig(id))
      return response
    } catch (error) {
      dispatch(updateCampaignReportCompanyRequestError(true))
      return Promise.reject(error)
    }
  }

interface ISetGroup {
  id: number | string
  page?: number
  query?: string
}

export const setGroup =
  ({ id, page = 1, query }: ISetGroup) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    dispatch(updateCampaignReportGroupRequestError(false))
    const url = getQuery(`${API_URL}/telegram_editor/${id}/adsets/`, page, query)

    dispatch({ type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_GROUP_LOADING })

    try {
      const response = await customAxios(url, 'GET', null, true)

      dispatch(setCampaignReportSetGroup(response.data))
      dispatch(setTableConfig(id))
      return response
    } catch (error) {
      dispatch(updateCampaignReportGroupRequestError(true))
      return Promise.reject(error)
    }
  }

export const setUndistributed =
  ({ page, id, query }: ISetAds) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    dispatch(updateCampaignReportUndistributedRequestError(false))
    let queryParams = `organized=false`

    if (query) {
      queryParams = `${query}&${queryParams}`
    }

    const url = getQuery(`${API_URL}/telegram_editor/${id}/ads/`, page, queryParams)

    dispatch({ type: CampaignReportActionTypes.COMPAIGN_REPORT_SET_UNDISTRIBUTED_LOADING })

    try {
      const response = await customAxios(url, 'GET', null, true)

      dispatch(setCampaignReportSetUndistributed(response.data))
      dispatch(setTableConfig(id))
      return response
    } catch (error) {
      dispatch(updateCampaignReportUndistributedRequestError(true))
      return Promise.reject(error)
    }
  }

export const setTableConfig =
  (id: number | string) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    dispatch({ type: UpdateFilterStepsTypes.CAMPAIGN_REPORT_TABLE_CONFIG_LOADING })

    const url = `${API_URL}/telegram_editor/${id}/table_config/`

    try {
      const response = await customAxios(url, 'GET', null, true)
      dispatch(setCampaignReportTableConfig(response.data))
      return response
    } catch (error) {
      dispatch(
        openCampaignEditorReportModal({
          name: MODALS.ERROR_ASIDE,
          data: { text: '' },
        })
      )
      return Promise.reject(error)
    }
  }

export const setBudgets =
  (id: string, body: ISetBudgets) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/telegram_bidder/budgets/account/${id}/bulk/create/`

    try {
      dispatch(setAsideLoading(true))
      const response = await customAxios(url, 'POST', body, true)
      return response
    } catch (error: any) {
      return Promise.reject(error)
    } finally {
      dispatch(setAsideLoading(false))
    }
  }

export const updateBudgets =
  (id: string, body: IUpdateBudgets) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/telegram_bidder/budgets/account/${id}/bulk/update/`

    try {
      dispatch(setAsideLoading(true))
      const response = await customAxios(url, 'POST', body, true)
      return response
    } catch (error: any) {
      return Promise.reject(error)
    } finally {
      dispatch(setAsideLoading(false))
    }
  }

export const deleteBudgets =
  (id: string, body: IDeleteBudgets) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/telegram_bidder/budgets/account/${id}/bulk/delete/`

    try {
      dispatch(setAsideLoading(true))
      const response = await customAxios(url, 'POST', body, true)
      return response
    } catch (error: any) {
      return Promise.reject(error)
    } finally {
      dispatch(setAsideLoading(false))
    }
  }

export const setDaysStop =
  (id: string, body: ISetDaysStop) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/telegram_bidder/day_stops/account/${id}/bulk/create/`

    try {
      dispatch(setAsideLoading(true))
      const response = await customAxios(url, 'POST', body, true)
      return response
    } catch (error: any) {
      return Promise.reject(error)
    } finally {
      dispatch(setAsideLoading(false))
    }
  }

export const updateDaysStop =
  (id: string, body: IUpdateDaysStop) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/telegram_bidder/day_stops/account/${id}/bulk/update/`

    try {
      dispatch(setAsideLoading(true))
      const response = await customAxios(url, 'POST', body, true)
      return response
    } catch (error: any) {
      return Promise.reject(error)
    } finally {
      dispatch(setAsideLoading(false))
    }
  }

export const deleteDaysStop =
  (id: string, body: IDeleteDaysStop) =>
  async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any>> => {
    const url = `${API_URL}/telegram_bidder/day_stops/account/${id}/bulk/delete/`

    try {
      dispatch(setAsideLoading(true))
      const response = await customAxios(url, 'POST', body, true)
      return response
    } catch (error: any) {
      return Promise.reject(error)
    } finally {
      dispatch(setAsideLoading(false))
    }
  }

export function setSelectedUndistributedIds(data: string[]): AnyAction {
  return { type: CampaignReportActionTypes.SET_SELECTED_UNDISTRIBUTED_IDS, data }
}

export function setSelectedAdIds(data: string[]): AnyAction {
  return { type: CampaignReportActionTypes.SET_SELECTED_AD_IDS, data }
}

export function setSelectedAdsetIds(data: string[]): AnyAction {
  return { type: CampaignReportActionTypes.SET_SELECTED_ADSET_IDS, data }
}

export function setSelectedCampaignIds(data: string[]): AnyAction {
  return { type: CampaignReportActionTypes.SET_SELECTED_CAMPAIGN_IDS, data }
}

export function setSelectedAllRowsType(data: { [key: string]: boolean }): AnyAction {
  return { type: CampaignReportActionTypes.SET_SELECTED_ALL_ROWS_TYPE, data }
}

export function setUndistributedError(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_UNDISTRIBUTED_ERROR, data }
}

export function setAdError(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_AD_ERROR, data }
}

export function setAdsetError(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_ADSET_ERROR, data }
}

export function setCampaignError(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_CAMPAIGN_ERROR, data }
}

export function setUndistributedLoading(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_UNDISTRIBUTED_LOADING, data }
}

export function setAdLoading(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_AD_LOADING, data }
}

export function setAdsetLoading(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_ADSET_LOADING, data }
}

export function setCampaignLoading(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_CAMPAIGN_LOADING, data }
}

export function setForceUpdateLoading(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_FORCE_UPDATE_LOADING, data }
}

export function setAsideActiveNavItem(data: MassEditingTypes): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_ACTIVE_NAV_ITEM, data }
}

export function setAsideNavError(type: MassEditingTypes, value: IAsideNavErrors): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_NAV_ERROR, data: { type, value } }
}
export function setAsideNavDisabled(type: MassEditingTypes, value: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_NAV_DISABLED, data: { type, value } }
}

export function setAsideResetNavDisabled(): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_RESET_NAV_DISABLED }
}

export function setAsideLoading(data: boolean): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_LOADING, data }
}

export function setAsideOpened(data: { isOpened: boolean; isAll: boolean }): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_OPENED, data }
}

export function setAsideStopDateRule(data: { isSetStopDateRule: boolean }): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_STOP_DATE_RULE, data }
}

export function setAsideLimitRule(data: { isSetLimitRule: boolean }): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_LIMIT_RULE, data }
}

export function setAsideValues(data: AsideValues[]): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_VALUES, data }
}

export function setAllAsideValues(data: AsideValues[]): AnyAction {
  return { type: CampaignReportActionTypes.SET_ALL_ASIDE_VALUES, data }
}

export function updateAsideValues(data: AsideValues[]): AnyAction {
  return { type: CampaignReportActionTypes.UPDATE_ASIDE_VALUES, data }
}

export function removeAsideValues(): AnyAction {
  return { type: CampaignReportActionTypes.REMOVE_ASIDE_VALUES }
}

export function removeAllAsideValues(): AnyAction {
  return { type: CampaignReportActionTypes.REMOVE_ALL_ASIDE_VALUES }
}

export function setAsideLimitDraft(data: AsideLimitDraft): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_LIMIT_DRAFT, data }
}

export function removeAsideLimitDraft(): AnyAction {
  return { type: CampaignReportActionTypes.REMOVE_ASIDE_LIMIT_DRAFT }
}

export function setAsideStopDateDraft(data: AsideStopDateDraft): AnyAction {
  return { type: CampaignReportActionTypes.SET_ASIDE_STOP_DATE_DRAFT, data }
}

export function removeAsideStopDateDraft(): AnyAction {
  return { type: CampaignReportActionTypes.REMOVE_ASIDE_STOP_DATE_DRAFT }
}

export const getAdsPageByPage = async (id: string, dispatch: MyThunkDispatch, type: MassEditingTypes) => {
  dispatch(setAsideLoading(true))
  const allAds: AdsResult[] = []
  const getAds = async (page = 1): Promise<any> => {
    const url = `${API_URL}/telegram_editor/${id}/ads/?page=${page}`
    try {
      const response = await customAxios(url, 'GET', null, true)
      const { data } = response
      const { page_count, results } = data

      const isHasLimitInherits = checkLimitAdInherits(results)
      const isHasStopDateInherits = checkStopDateAdInherits(results)

      if (type === MassEditingTypes.limit) {
        if (isHasLimitInherits) {
          showAdFatherInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }
        if (isHasStopDateInherits) {
          dispatch(setAsideNavDisabled(MassEditingTypes.stop, true))
        }
      }

      if (type === MassEditingTypes.stop) {
        if (isHasStopDateInherits) {
          showAdFatherInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }
        if (isHasLimitInherits) {
          dispatch(setAsideNavDisabled(MassEditingTypes.limit, true))
        }
      }

      allAds.push(...results)

      if (page < page_count) {
        page++
        await new Promise((resolve) => setTimeout(resolve, DELAY_FOR_MACROTASK_QUEUE))
        return await getAds(page)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  await getAds()

  const newAside = createNewAside(allAds, DailyLevel.AD)
  dispatch(setAsideValues(newAside))
  dispatch(setAllAsideValues(newAside))
  dispatch(setAsideOpened({ isOpened: true, isAll: true }))
  dispatch(setAsideLoading(false))
}

export const getAdsetsPageByPage = async (id: string, dispatch: MyThunkDispatch, type: MassEditingTypes) => {
  dispatch(setAsideLoading(true))
  const allAdsets: AdsetsResult[] = []
  const getAdsets = async (page = 1): Promise<any> => {
    const url = `${API_URL}/telegram_editor/${id}/adsets/?page=${page}`
    try {
      const response = await customAxios(url, 'GET', null, true)
      const { data } = response
      const { page_count, results } = data

      const { isHasLimitChild, isHasLimitFather } = checkLimitAdsetsInherits(results)
      const { isHasStopDateChild, isHasStopDateFather } = checkStopDateAdsetsInherits(results)

      if (type === MassEditingTypes.limit) {
        if (isHasLimitChild) {
          showAdsetsChildInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }
        if (isHasLimitFather) {
          showAdsetsFatherInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }

        if (isHasStopDateChild || isHasStopDateFather) {
          dispatch(setAsideNavDisabled(MassEditingTypes.stop, true))
        }
      }

      if (type === MassEditingTypes.stop) {
        if (isHasStopDateChild) {
          showAdsetsChildInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }
        if (isHasStopDateFather) {
          showAdsetsFatherInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }

        if (isHasLimitChild || isHasLimitFather) {
          dispatch(setAsideNavDisabled(MassEditingTypes.limit, true))
        }
      }

      allAdsets.push(...results)

      if (page < page_count) {
        page++
        await new Promise((resolve) => setTimeout(resolve, DELAY_FOR_MACROTASK_QUEUE))
        return await getAdsets(page)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  await getAdsets()

  const newAside = createNewAside(allAdsets, DailyLevel.ADSET)
  dispatch(setAsideValues(newAside))
  dispatch(setAllAsideValues(newAside))
  Boolean(newAside.length) && dispatch(setAsideOpened({ isOpened: true, isAll: true }))
  dispatch(setAsideLoading(false))
}

export const getCampaignPageByPage = async (id: string, dispatch: MyThunkDispatch, type: MassEditingTypes) => {
  dispatch(setAsideLoading(true))
  const allCampaigns: CampaignsResult[] = []
  const getCampaigns = async (page = 1): Promise<any> => {
    const url = `${API_URL}/telegram_editor/${id}/campaigns/?page=${page}`
    try {
      const response = await customAxios(url, 'GET', null, true)
      const { data } = response
      const { page_count, results } = data

      const isHasLimitInherits = checkLimitCampaignsInherits(results)
      const isHasStopDateInherits = checkStopDateCampaignsInherits(results)

      if (type === MassEditingTypes.limit) {
        if (isHasLimitInherits) {
          showCampaignsChildInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }
        if (isHasStopDateInherits) {
          dispatch(setAsideNavDisabled(MassEditingTypes.stop, true))
        }
      }

      if (type === MassEditingTypes.stop) {
        if (isHasStopDateInherits) {
          showCampaignsChildInheritsAlert(dispatch)
          dispatch(setAsideLoading(false))
          return Promise.reject()
        }
        if (isHasLimitInherits) {
          dispatch(setAsideNavDisabled(MassEditingTypes.limit, true))
        }
      }

      allCampaigns.push(...results)

      if (page < page_count) {
        page++
        await new Promise((resolve) => setTimeout(resolve, DELAY_FOR_MACROTASK_QUEUE))
        return await getCampaigns(page)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  await getCampaigns()

  const newAside = createNewAside(allCampaigns, DailyLevel.CAMPAIGN)
  dispatch(setAsideValues(newAside))
  dispatch(setAllAsideValues(newAside))
  Boolean(newAside.length) && dispatch(setAsideOpened({ isOpened: true, isAll: true }))
  dispatch(setAsideLoading(false))
}

export const getUndistributedPageByPage = async (id: string, dispatch: MyThunkDispatch) => {
  dispatch(setAsideLoading(true))
  const allUndistributed: AdsResult[] = []
  const getUndistributed = async (page = 1): Promise<any> => {
    const url = `${API_URL}/telegram_editor/${id}/campaigns/?page=${page}&organized=false`
    try {
      const response = await customAxios(url, 'GET', null, true)
      const { data } = response
      const { page_count, results } = data
      allUndistributed.push(...results)

      if (page < page_count) {
        page++
        await new Promise((resolve) => setTimeout(resolve, DELAY_FOR_MACROTASK_QUEUE))
        return await getUndistributed(page)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  await getUndistributed()

  const newAside = createNewAside(allUndistributed, DailyLevel.UNDISTRIBUTED)

  dispatch(setAsideValues(newAside))
  dispatch(setAllAsideValues(newAside))
  Boolean(newAside.length) && dispatch(setAsideOpened({ isOpened: true, isAll: true }))
  dispatch(setAsideLoading(false))
}
