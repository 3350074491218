import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 23, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71035 2.70001H17.6822C19.0707 2.70001 20.1963 3.82561 20.1963 5.21407V14.4638C20.1963 15.8524 19.0707 16.9779 17.6822 16.9779L14.9979 16.9779C14.714 16.9779 14.4394 17.0794 14.2236 17.2639L11.9831 19.0201C11.5456 19.3943 10.9008 19.3943 10.4634 19.0201L8.22292 17.2639C8.00711 17.0794 7.73252 16.9779 7.4486 16.9779L4.71035 16.9779C3.32189 16.9779 2.19629 15.8524 2.19629 14.4639V5.21407C2.19629 3.82561 3.32189 2.70001 4.71035 2.70001ZM7.96937 11.6007L11.1885 14.3205L14.4076 11.6007C14.7625 11.3008 15.047 10.8993 15.191 10.4278C15.7821 8.49184 14.0421 6.25775 12.1113 7.23315C12.0667 7.25575 12.0222 7.27979 11.9779 7.30545L11.1885 7.76218L10.3991 7.30545C10.3548 7.27979 10.3104 7.25575 10.2657 7.23315C8.33495 6.25775 6.59495 8.49184 7.18605 10.4278C7.32996 10.8993 7.61454 11.3008 7.96937 11.6007Z"
        fill={fill}
      />
    </svg>
  )
}
