import React from 'react'
import PropTypes from 'prop-types'

import { ButtonBlock, ButtonLink, ExternalLink } from './styled'
import { IButtonProps, SizeTypes, ThemeTypes } from './types'

const componentsDict: any = {
  default: (props: any) => <ButtonBlock {...props}>{props.children}</ButtonBlock>,
  external_link: (props: any) => (
    <ExternalLink href={props.href || '/'} {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </ExternalLink>
  ),
  link: (props: any) => {
    const { bType, theme, size, ...otherProps } = props
    return (
      <ButtonLink to={props.to || '/'} theme={theme} onClick={props.onClick} size={size} {...otherProps}>
        {props.children}
      </ButtonLink>
    )
  },
}

/**
 * Component for `<button>`, `<a>` or `<Link>`, which are look like button block.
 */
const Button = React.forwardRef(({ size = 'default', theme = 'default', ...props }: IButtonProps, ref: any) => {
  const { bType } = props
  const resultType = bType || 'default'
  return componentsDict[resultType]({ size, theme, ...props, ref })
})

Button.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'extraSmall']) as PropTypes.Validator<SizeTypes>,
  theme: PropTypes.oneOf([
    'primary',
    'brand',
    'pink',
    'default',
    'gray',
    'text',
    'yellow',
    'light_gray',
    'bordered_text',
    'purple',
    'bordered_purple',
    'linked_text',
  ]) as PropTypes.Validator<ThemeTypes>,
  bType: PropTypes.oneOf(['default', 'external_link', 'link']),
}

export default Button
