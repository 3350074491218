import { Reducer } from 'redux'

import { CAMPAIGN_FILTERS } from '../../pages/Audiences/components/CampaignFilter/consts'
import { AudiencesActionTypes, IAudiencesState } from './types'

const initialState: IAudiencesState = {
  currentStep: 1,
  campaignAdset: null,
  campaignFilter: CAMPAIGN_FILTERS[0],
  campaigns: [],
  errorObjects: [],
  industries: [],
  isErrorModalShown: false,
  isConfirmModalShown: false,
  isModalLoaderShown: false,
  isSuccessModalShown: false,
  modalActivateMode: null,
  modalCopyMode: null,
  reportStatus: {
    benchmarks_is_ready: false,
    targetings_is_ready: false,
  },
  selectedAccount: null,
  selectedIndustry: undefined,
  selectedTargeting: [],
  forecast: null,
  isHiddenHeaderNotification: false,
  isHiddenDidUseNotification: false,
}

const reducer: Reducer<IAudiencesState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AudiencesActionTypes.SET_DID_USE_NOTIFICATION_VISIBILITY: {
      return {
        ...state,
        isHiddenDidUseNotification: action.data,
      }
    }
    case AudiencesActionTypes.SET_HEADER_NOTIFICATION_VISIBILITY: {
      return {
        ...state,
        isHiddenHeaderNotification: action.data,
      }
    }
    case AudiencesActionTypes.ADD_SELECTED_TARGETING: {
      return {
        ...state,
        selectedTargeting: [action.data, ...state.selectedTargeting],
      }
    }
    case AudiencesActionTypes.ADD_USED_SELECTED_TARGETING: {
      const targetings = state.campaignAdset ? [...state.campaignAdset.targetings] : []
      return {
        ...state,
        selectedTargeting: targetings,
      }
    }
    case AudiencesActionTypes.REMOVE_SELECTED_TARGETING: {
      return {
        ...state,
        selectedTargeting: [...state.selectedTargeting.filter((target) => target.id.toString() !== action.data)],
      }
    }
    case AudiencesActionTypes.CLEAR_SELECTED_TARGETING: {
      return {
        ...state,
        selectedTargeting: [],
      }
    }
    case AudiencesActionTypes.SET_FORECAST: {
      return {
        ...state,
        forecast: action.data,
      }
    }
    case AudiencesActionTypes.SET_SELECTED_ACCOUNT: {
      return {
        ...state,
        selectedAccount: action.data,
      }
    }
    case AudiencesActionTypes.SET_SELECTED_INDUSTRY: {
      return {
        ...state,
        selectedIndustry: action.data,
      }
    }
    case AudiencesActionTypes.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.data,
      }
    }
    case AudiencesActionTypes.SET_INDUSTRIES: {
      return {
        ...state,
        industries: [...action.data],
      }
    }
    case AudiencesActionTypes.SET_REPORT_STATUS: {
      return {
        ...state,
        reportStatus: { ...action.data },
      }
    }
    case AudiencesActionTypes.SET_CAMPAIGN_FILTER: {
      return {
        ...state,
        campaignFilter: action.data,
      }
    }
    case AudiencesActionTypes.SET_CAMPAIGNS: {
      return {
        ...state,
        campaigns: [...action.data],
        loading: false,
      }
    }
    case AudiencesActionTypes.SET_CAMPAIGN_ADSET: {
      return {
        ...state,
        campaignAdset: action.data,
      }
    }
    case AudiencesActionTypes.SET_CONFIRM_MODAL: {
      return {
        ...state,
        isConfirmModalShown: action.data,
      }
    }
    case AudiencesActionTypes.SET_ERROR_MODAL: {
      return {
        ...state,
        isErrorModalShown: action.data,
      }
    }
    case AudiencesActionTypes.SET_SUCCESS_MODAL: {
      return {
        ...state,
        isSuccessModalShown: action.data,
      }
    }
    case AudiencesActionTypes.SET_ERROR_MODAL_OBJECTS: {
      return {
        ...state,
        errorObjects: action.data,
      }
    }
    case AudiencesActionTypes.SET_MODAL_COPY_MODE: {
      return {
        ...state,
        modalCopyMode: action.data,
      }
    }
    case AudiencesActionTypes.SET_MODAL_ACTIVATE_MODE: {
      return {
        ...state,
        modalActivateMode: action.data,
      }
    }
    case AudiencesActionTypes.SET_MODAL_LOADER: {
      return {
        ...state,
        isModalLoaderShown: action.data,
      }
    }
    case AudiencesActionTypes.SET_MODAL_NULL_MODES: {
      return {
        ...state,
        modalCopyMode: null,
        modalActivateMode: null,
      }
    }
    case AudiencesActionTypes.RESET_AUDIENCES_STATE: {
      return {
        ...state,
        campaignAdset: null,
        currentStep: 1,
        errorObjects: [],
        forecast: null,
        isErrorModalShown: false,
        isConfirmModalShown: false,
        isModalLoaderShown: false,
        isSuccessModalShown: false,
        modalCopyMode: null,
        modalActivateMode: null,
        selectedTargeting: [],
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as audiencesReducer }
