import React, { ReactNode } from 'react'

import useStyles from './styles'

export interface IPageWrapper {
  children: ReactNode | null
}

const PageWrapper: React.FC<IPageWrapper> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.pageWrapper}>{children}</div>
}

export default PageWrapper
